import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { Flex, Text, Heading, ButtonSystem, Box } from "aplication-yetz";
import {
    Formik,
    Field,
    Form,
    ErrorMessage,
    FormikProps,
    FormikHelpers,
} from "formik";

import { useProduto, Variacao } from "../../Hook/useProduto";

import { api } from "../../services/";
import {
    formataMoney,
    formatCurrentYETZ,
    realParaFloat,
} from "../../util/helper";
import { Input } from "../Input";
import { Toggle } from "../Toggle";
import { Select, SelectAsync } from "../Select";
import { Option } from "../../components/Select/types";

import { FormVariacao } from "./type";
import { Schema, initialValues } from "./config";

import fecharModalImg from "../../assets/sistema/fechar-modal.svg";
import { ModalVariacao } from "./styles";
import { FormVoucher } from "./formVoucher";
import { FormCupom } from "./formCupom";
import { FormAgendaLimpeza } from "./formAgendaLimpeza";

interface NovaVariacaoProps {
    rest: {
        onClose: () => void;
        id: number;
        variacao: Variacao;
    };
}

export function NovaVariacao(props: NovaVariacaoProps) {
    const { rest } = props;
    const { variacao } = rest;
    const clearSelect = useRef<HTMLButtonElement>(null);
    const { storeVariacao, updateVariacao } = useProduto();
    const [loading, setLoading] = useState<boolean>(false);
    const [value, setValue] = useState<FormVariacao>(initialValues);
    const [optionsTodo, setOptionsTodo] = useState<Option[]>([]);
    const [optionsSmash, setOptionsSmash] = useState<Option[]>([]);
    const [optionsGiffty, setOptionsGiffty] = useState<Option[]>([]);

    const [selectedOptionSmash, setSelectedOptionSmash] = useState<Option[]>(
        []
    );
    const [selectedOptionTodo, setSelectedOptionTodo] = useState<Option[]>([]);
    const [selectedOptionGiffty, setSelectedOptionGiffty] = useState<Option[]>(
        []
    );

    const [disabledSmash, setDisabledSmash] = useState(false);
    const [disabledTodo, setDisabledTodo] = useState(false);
    const [disabledGiffty, setDisabledGiffty] = useState(false);

    const hasVariacao = Object.values(variacao).length ? true : false;

    const tipoVariacao: Option[] = useMemo(
        () => [
            {
                label: "CUPOM",
                value: 1,
            },
            {
                label: "VOUCHER",
                value: 2,
            },
        ],
        []
    );

    async function handleSubmit(
        values: FormVariacao,
        actions: FormikHelpers<FormVariacao>
    ) {
        setLoading(true);

        let {
            valor_reais,
            valor_yetz,
            fornecedor_id_smash,
            fornecedor_id_todo,
            fornecedor_id_giffit,
        } = values;

        const metas: any = [];

        let smash: any = {};
        if (fornecedor_id_smash === 1) {
            smash = {
                fornecedor_id: 1,
                key: values.key_smash,
            };
            metas.push(smash);
        }

        let todo: any = {};
        if (fornecedor_id_todo === 3) {
            todo = {
                fornecedor_id: 3,
                key: values.key_todo,
            };
            metas.push(todo);
        }

        let giffit: any = {};
        if (fornecedor_id_giffit === 2) {
            giffit = {
                fornecedor_id: 2,
                key: values.key_giffty,
            };
            metas.push(giffit);
        }

        valor_yetz = valor_yetz.replace(/\./g, "");

        const values_format: FormVariacao = {
            // ...restValues,
            nome: values.nome,
            status: values.status,
            alerta_estoque: values.alerta_estoque,
            valor_reais: realParaFloat(valor_reais) || (null as any),
            valor_yetz: parseFloat(valor_yetz) || (null as any),
            metas,
            tipo: values.tipo || "voucher",
            porcentagem: values.porcentagem || null,
            outlet_limpeza_agendada: values.outlet_limpeza_agendada,
            outlet_dia_limpeza: values.outlet_dia_limpeza || null,
            outlet_hora_limpeza: values.outlet_hora_limpeza || null,
        };

        if (!values_format?.outlet_dia_limpeza)
            delete values_format.outlet_dia_limpeza;
        if (!values_format?.outlet_hora_limpeza)
            delete values_format.outlet_hora_limpeza;

        console.log(values_format);

        const response = hasVariacao
            ? await updateVariacao(values_format, variacao.id)
            : await storeVariacao({
                  ...values_format,
                  produto_id: rest.id,
              });

        if (response.status === 201 || response.status === 200) {
            actions.resetForm();
            rest.onClose();
            if (clearSelect.current) {
                clearSelect.current.click();
            }
        }

        setLoading(false);
    }

    useEffect(() => {
        if (hasVariacao) {
            const {
                nome,
                status,
                valor_yetz,
                valor_reais,
                alerta_estoque,
                metas,
                tipo,
            } = variacao;

            setValue({
                nome,
                status,
                valor_yetz: formatCurrentYETZ(valor_yetz),
                valor_reais: formataMoney(
                    typeof valor_reais === "number"
                        ? valor_reais + ""
                        : valor_reais
                ),
                alerta_estoque: alerta_estoque + "",
                fornecedor_id_smash: metas?.find((m) => m.fornecedor_id === 1)
                    ?.fornecedor_id,
                fornecedor_id_giffit: metas?.find((m) => m.fornecedor_id === 2)
                    ?.fornecedor_id,
                key_smash: metas?.find((m) => m.fornecedor_id === 1)?.key,
                fornecedor_id_todo: metas?.find((m) => m.fornecedor_id === 3)
                    ?.fornecedor_id,
                key_todo: metas?.find((m) => m.fornecedor_id === 3)?.key,
                key_giffty: metas?.find((m) => m.fornecedor_id === 2)?.key,
                //pegar banco
                tipo: tipo || "voucher",
                tipo_desconto: "",

                // fazer
                outlet_limpeza_agendada: variacao?.outlet_limpeza_agendada,
                outlet_dia_limpeza: variacao?.outlet_dia_limpeza,
                outlet_hora_limpeza: variacao?.outlet_hora_limpeza,
            });

            setDisabledSmash(
                metas?.find((m) => m.fornecedor_id === 1)?.fornecedor_id === 1
                    ? false
                    : true
            );
            setDisabledGiffty(
                metas?.find((m) => m.fornecedor_id === 2)?.fornecedor_id === 2
                    ? false
                    : true
            );
            setDisabledTodo(
                metas?.find((m) => m.fornecedor_id === 3)?.fornecedor_id === 3
                    ? false
                    : true
            );
        }
    }, [hasVariacao, variacao]);

    const arrayMatchSmash: Option[] = [];
    const arrayMatchTodo: Option[] = [];
    const arrayMatchGiffty: Option[] = [];

    async function produtosExternal(id: number) {
        try {
            const response = await api.get(`fornecedor/${id}/produtos-api`);

            const { data } = response;
            if (id === 1) {
                setOptionsSmash(
                    data.map((item: any) => ({
                        value: item.key,
                        label: item.description,
                    }))
                );
            } else if (id === 2) {
                setOptionsGiffty(
                    data.map((item: any) => ({
                        value: item.key,
                        label: item.description,
                    }))
                );
            } else if (id === 3) {
                setOptionsTodo(
                    data.map((item: any) => ({
                        value: item.key,
                        label: item.description,
                    }))
                );
            }

            data.forEach((item: any) => {
                variacao.metas?.forEach((m) => {
                    if (
                        m.key === item.key &&
                        parseFloat(variacao.valor_reais) >=
                            parseFloat(item.min_value) &&
                        parseFloat(variacao.valor_reais) <=
                            parseFloat(item.max_value)
                    ) {
                        if (m.fornecedor_id === 1) {
                            arrayMatchSmash.push({
                                value: item.key,
                                label: item.description,
                            });
                        } else if (m.fornecedor_id === 2) {
                            arrayMatchGiffty.push({
                                value: item.key,
                                label: item.description,
                            });
                        } else if (m.fornecedor_id === 3) {
                            arrayMatchTodo.push({
                                value: item.key,
                                label: item.description,
                            });
                        }
                    }
                });
            });
            if (id === 1) {
                setSelectedOptionSmash(arrayMatchSmash);
            } else if (id === 2) {
                setSelectedOptionGiffty(arrayMatchGiffty);
            } else if (id === 3) {
                setSelectedOptionTodo(arrayMatchTodo);
            }
        } catch (error: any) {
            console.log(error);
        }
    }

    const hasTipo = useCallback(() => {
        if (hasVariacao) {
            return tipoVariacao.filter(
                (item) =>
                    item.label.toLowerCase() === variacao?.tipo.toLowerCase()
            )[0];
        }
        return null;
    }, [hasVariacao, tipoVariacao, variacao?.tipo]);

    return (
        <ModalVariacao>
            <Flex alignItems="center" justifyContent="space-between" mb="34px">
                <Heading as="h2" fontWeight="600">
                    {hasVariacao ? "Editar " : "Nova "} variação
                </Heading>

                <button
                    onClick={() => {
                        rest.onClose();
                        setValue(initialValues);
                    }}
                    type="button"
                >
                    <img src={fecharModalImg} alt="" />
                </button>
            </Flex>

            <Formik
                initialValues={value}
                enableReinitialize
                validationSchema={Schema}
                onSubmit={async (values: FormVariacao, actions) => {
                    await handleSubmit(values, actions);
                }}
            >
                {(props: FormikProps<FormVariacao>) => {
                    const { setFieldValue, values } = props;
                    const {
                        fornecedor_id_smash,
                        fornecedor_id_todo,
                        fornecedor_id_giffit,
                        tipo,
                    } = values;

                    return (
                        <Form>
                            <div className="container-form">
                                <Field
                                    component={Input}
                                    name="nome"
                                    label="NOME"
                                />
                                <ErrorMessage component="small" name="nome" />
                            </div>

                            <div className="container-form">
                                <Select
                                    initalValue={hasTipo()}
                                    placeholder="TIPO DE VARIAÇÃO"
                                    onChange={(item) => {
                                        setFieldValue(
                                            "tipo",
                                            item.label.toLowerCase()
                                        );
                                        setFieldValue("valor_reais", "");
                                        setFieldValue("valor_yetz", "");
                                        setFieldValue("desconto", "");
                                        setFieldValue("porcentagem", "");
                                    }}
                                    options={tipoVariacao}
                                />
                            </div>

                            <Field
                                component={FormAgendaLimpeza}
                                variacao={variacao}
                            />

                            {tipo === "cupom" ? (
                                <Field component={FormCupom} />
                            ) : (
                                <Field component={FormVoucher} />
                            )}

                            {hasVariacao && (
                                <>
                                    <Text
                                        fontWeight="600"
                                        fontSize="mdl"
                                        color="black"
                                        mb="30px"
                                    >
                                        Compras via API
                                    </Text>

                                    <Flex
                                        justifyContent="space-between"
                                        width="100%"
                                    >
                                        <Text fontWeight="600" mb="20px">
                                            SMASHPOINTS
                                        </Text>
                                        <Toggle
                                            active={
                                                fornecedor_id_smash === 1
                                                    ? true
                                                    : false
                                            }
                                            onClick={() => {
                                                setFieldValue(
                                                    "fornecedor_id_smash",
                                                    fornecedor_id_smash === 1
                                                        ? 0
                                                        : 1
                                                );
                                                setDisabledSmash(
                                                    !disabledSmash
                                                );
                                            }}
                                        />
                                    </Flex>

                                    <Box mb="32px">
                                        <SelectAsync
                                            clear={clearSelect}
                                            disabled={disabledSmash}
                                            defaultValue={
                                                selectedOptionSmash &&
                                                selectedOptionSmash[0]
                                            }
                                            onChange={(value) =>
                                                setFieldValue(
                                                    "key_smash",
                                                    value.value
                                                )
                                            }
                                            placeholder="PRODUTO VINCULADO"
                                            options={optionsSmash}
                                            setOptions={setOptionsSmash}
                                            getOptions={async () =>
                                                await produtosExternal(1)
                                            }
                                        />
                                    </Box>

                                    <Flex
                                        justifyContent="space-between"
                                        width="100%"
                                    >
                                        <Text fontWeight="600" mb="20px">
                                            GIFFTY
                                        </Text>
                                        <Toggle
                                            active={
                                                fornecedor_id_giffit === 2
                                                    ? true
                                                    : false
                                            }
                                            onClick={() => {
                                                setFieldValue(
                                                    "fornecedor_id_giffit",
                                                    fornecedor_id_giffit === 2
                                                        ? 0
                                                        : 2
                                                );
                                                setDisabledGiffty(
                                                    !disabledGiffty
                                                );
                                            }}
                                        />
                                    </Flex>

                                    <Box mb="32px">
                                        <SelectAsync
                                            clear={clearSelect}
                                            disabled={disabledGiffty}
                                            defaultValue={
                                                selectedOptionGiffty &&
                                                selectedOptionGiffty[0]
                                            }
                                            onChange={(value) =>
                                                setFieldValue(
                                                    "key_giffty",
                                                    value.value
                                                )
                                            }
                                            placeholder="PRODUTO VINCULADO"
                                            options={optionsGiffty}
                                            setOptions={setOptionsGiffty}
                                            getOptions={() =>
                                                produtosExternal(2)
                                            }
                                        />
                                    </Box>

                                    <Flex
                                        justifyContent="space-between"
                                        width="100%"
                                    >
                                        <Text fontWeight="600" mb="20px">
                                            TODO CARTÕES
                                        </Text>
                                        <Toggle
                                            active={
                                                fornecedor_id_todo === 3
                                                    ? true
                                                    : false
                                            }
                                            onClick={() => {
                                                setFieldValue(
                                                    "fornecedor_id_todo",
                                                    fornecedor_id_todo === 3
                                                        ? 0
                                                        : 3
                                                );
                                                setDisabledTodo(!disabledTodo);
                                            }}
                                        />
                                    </Flex>

                                    <Box mb="32px">
                                        <SelectAsync
                                            clear={clearSelect}
                                            disabled={disabledTodo}
                                            defaultValue={
                                                selectedOptionTodo &&
                                                selectedOptionTodo[0]
                                            }
                                            onChange={(value) =>
                                                setFieldValue(
                                                    "key_todo",
                                                    value.value
                                                )
                                            }
                                            placeholder="PRODUTO VINCULADO"
                                            options={optionsTodo}
                                            setOptions={setOptionsTodo}
                                            getOptions={() =>
                                                produtosExternal(3)
                                            }
                                        />
                                    </Box>
                                </>
                            )}

                            <ButtonSystem
                                mt="16px"
                                p="16px 32px"
                                fontSize="xs"
                                loading={loading}
                                type="submit"
                                width={132}
                                height={53}
                                heightSpiner={30}
                                widthSpnier={30}
                            >
                                {hasVariacao ? "Editar" : "Adicionar"}
                            </ButtonSystem>
                        </Form>
                    );
                }}
            </Formik>
        </ModalVariacao>
    );
}
